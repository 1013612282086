define('Share',['jquery', 'Configuration', 'SocialSharePrivacy.Facebook', 'SocialSharePrivacy.Flattr', 'SocialSharePrivacy.GooglePlus', 'SocialSharePrivacy.Twitter'],
	function($, Configuration) {

	function initializeShare() {
		$(document).ready(function() {
			// initialize generic recommendation module
			$('.recommend .share').socialSharePrivacy(Configuration.SocialSharePrivacy.options);
		});
	}

	if (Configuration['locale'] == 'de') {
		require(['SocialSharePrivacy.de',
			'SocialSharePrivacy.Facebook.de',
			'SocialSharePrivacy.GooglePlus.de',
			'SocialSharePrivacy.Twitter.de'
		], initializeShare);
	} else {
		initializeShare();
	}
});
