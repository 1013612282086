if (!("console" in window)) {
	window.console = {};
}
var names = ["log", "debug", "info", "warn", "error", "assert", "dir", "dirxml", "group", "groupEnd", "time", "timeEnd", "count", "trace", "profile", "profileEnd"];
for (var i = 0; i < names.length; ++i) {
	if (!(names[i] in window.console)) {
		window.console[names[i]] = function() {}
	}
}

require(['jquery', 'Configuration', 'GoogleAnalytics', 'Analytics', 'foundation.reveal', 'foundation.forms', 'foundation.topbar', 'Share'],
	function($, Configuration, GoogleAnalytics) {

	try {
		$(document).foundation();
	} catch (e) {
		// Ignore errors in foundation for now, as they are typically non-critical
	}

	GoogleAnalytics.setDomain('goqr.me');
	GoogleAnalytics.initialize();

	// UserVoice JavaScript SDK (only needed once on a page)
	(function(){
		var uv=document.createElement('script');
		uv.type='text/javascript';
		uv.async=true;
		uv.src='//widget.uservoice.com/7lgVuFzxBZDlWA3OgSw.js';
		var s=document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(uv,s)
	}());

	if (Configuration['locale'] == 'de') {
		UserVoice = window.UserVoice || [];
		UserVoice.push(['showTab', 'classic_widget', {
			mode: 'support',
			primary_color: '#4D4D4D',
			link_color: '#e59100',
			tab_label: 'Kontaktieren Sie uns',
			tab_color: '#cc6d00',
			tab_position: 'bottom-right',
			tab_inverted: false
		}]);
	}

	$(document).ready(function(){
		$buoop = {
			vs: {i:8,f:15,s:4,n:9},
			test: false
		};
		var e = document.createElement("script");
		e.setAttribute("type", "text/javascript");
		e.setAttribute("src", "https://browser-update.org/update.js");
		$('body').append(e);
	}());
});
define("Main", function(){});

