define('Pages/Index',['jquery', 'QrCodeGenerator', 'zazzle'], function($, Generator, Zazzle) {
	$(document).ready(function() {
		// TODO initialize QR code generator, Zazzle shop, embed script etc.
		Generator.initialize();


		/*************************************
		 *
		 * Accordion
		 *
		 * general idea from http://css-tricks.com/snippets/jquery/simple-jquery-accordion/
		 *************************************/
		// NOTE: this will not work with more than one accordion on the page
		var allPanels = $('.accordion > dd').hide();

		$('.accordion a.faq-cross-reference').click(function(e) {
			// open the target term when a link is clicked
			var $targetTerm = $($(this).attr('href'));
			$targetTerm.trigger('click');
		});
		$('.accordion > dt').click(function(e) {
			var $target = $(this).next();

			if (!$target.hasClass('active')) {
				allPanels.removeClass('active').slideUp();
				$target.addClass('active').slideDown();
			}
			return false;
		});
	});
});
