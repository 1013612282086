define('Pages/Vcards',['Configuration', 'QrCodeGenerator', 'zazzle'], function(Configuration, Generator, Zazzle) {
	var types = Configuration.QrCodeTypes;

	Generator.types = {
		vcard: types.complexVCard
	};

	$(document).ready(function() {
		Generator.initialize();
	});
});
