define('QrCodeDownload', ['jquery', 'Configuration', 'QrCodeDialogOptions'],
	function($, Configuration, QrCodeOptionsDialog) {

	var $downloadDialog = $('#qr-code-download-dialog'),
	    optionsDialog,
	    initialized = false;

	var DownloadDialog = {
		initialize: function(QrCodeGenerator) {
			optionsDialog = new QrCodeOptionsDialog(QrCodeGenerator, $downloadDialog.find('.qr-code-options-dialog'));

			initialized = true;
		}
	};

	$downloadDialog.on('opened', function() {
		optionsDialog.update();
	});
	$downloadDialog.on('closed', function() {
		$('#qr-code-recommend-dialog').foundation('reveal', 'open');
	});

	$downloadDialog.find('.button-download').on('click', function() {
		var params = optionsDialog.getParametersForDownload();
		var format = $(this).data('format');
		if (format == 'svg' || format == 'eps') {
			delete params['size'];
		}
		params['format'] = format;
		params['download'] = "1";

		window.location.href = Configuration.qrCodeBaseUri + $.param(params);
	});

	return DownloadDialog;
});

