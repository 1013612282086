define('zazzle',['jquery', 'Configuration', 'QrCodeGenerator', 'QrCodeDialogOptions', 'foundation.reveal', 'purl'],
	function($, Configuration, QrCodeGenerator, QrCodeDialogOptions) {

	var $widget = $('#zazzle-widget'), qrCodeData, selectedCategory;
	var $dialog = $('#zazzle-dialog');

	var optionsDialogObject = new QrCodeDialogOptions(QrCodeGenerator, $widget.find('.qr-code-options-dialog'));
	var messages = {
		'de': {
			'error-nocode': 'Bitte erstellen Sie zunächst einen QR-Code, bevor Sie den Shop besuchen.',
			'error-api': 'Der Online-Shop konnte auf Grund eines internen Fehlers nicht geöffnet werden. Bitte versuchen Sie es erneut.',
			'error-invalidcategory': 'Der Online-Shop konnte nicht geöffnet werden, die übergebene Kategorie ist unbekannt: ',
			'error-nocategory': 'Der Online-Shop konnte nicht geöffnet werden, es wurde keine Kategorie übergeben.',
			'error-nodata': 'Der Online-Shop konnte nicht geöffnet werden, da keine gültigen Daten übergeben wurden. Bitte generieren Sie einen QR-Code, bevor Sie den Shop öffnen.',
			'error-unknown': 'Unbekannter Fehler beim Öffnen des Shops.'
		},
		en: {
			'error-nocode': 'Please create a QR code before visiting the shop.',
			'error-api': 'The shop could not be opened due to an internal error. Please try again.',
			'error-invalidcategory': 'The shop could not be opened: unknown category ',
			'error-nocategory': 'Could not open shop, no category given.',
			'error-nodata': 'The shop could not be opened because of invalid data. Please create a QR code before visiting the shop.',
			'error-unknown': 'Unknown error while opening shop.'
		}
	};
	messages = messages[Configuration['locale']];
	if (messages == undefined) {
		console.error('Zazzle dialog: No messages found for locale ' + Configuration['locale']);
	}

	var invokeZazzleShop = function(category) {
		var params = {
			'lang': Configuration['locale'],
			qzone: optionsDialogObject.getQuietZone(),
			margin: 0,
			ecc: optionsDialogObject.getErrorCorrectionCode(),
			color: optionsDialogObject.getForegroundColor(),
			bgcolor: optionsDialogObject.getBackgroundColor(),
			'category': category,
			'charset-target': 'UTF-8'
		};

		switch (category) {
			case 'businesscards':
				params['vcard'] = QrCodeGenerator.getQrCodeFields('vcard');

				if (params['vcard']['firstname'] && params['vcard']['lastname']) {
					params['vcard']['name'] = params['vcard']['firstname'] + ' ' + params['vcard']['lastname'];
				} else if (params['vcard']['firstname']) {
					params['vcard']['name'] = params['vcard']['firstname'];
				} else if (params['vcard']['lastname']) {
					params['vcard']['name'] = params['vcard']['lastname'];
				}
				break;
			default:
				break;
		}
		params['data'] = QrCodeGenerator.getQrCodeContents();

		window.location.href = '/shop/?' + $.param(params);
	};

	$widget.find('.zazzle-link').click(function() {
		if (!QrCodeGenerator.getQrCodeContents()) {
			alert(messages['error-nocode']);
			return;
		}

		selectedCategory = $(this).data('category');

		$('#zazzle-dialog').foundation('reveal', 'open');
		$('#zazzle-button-proceed').data('category', selectedCategory);
	});
	$('#zazzle-button-proceed').click(function() {
		invokeZazzleShop(selectedCategory);

		return false;
	});
	$dialog.on('opened', function() {
		optionsDialogObject.update();
	});

	var url = $.url();
	if (url.param('shop-error') !== undefined) {
		var message;
		switch (url.param('shop-error')) {
			case 'api-error':
				message = messages['error-api'];
				break;

			case 'invalid-category':
				// do not use a check for undefined here, as can also fetch
				if (url.param('category')) {
					message = messages['error-invalidcategory'] + url.param('category') + '.';
				} else {
					message = messages['error-nocategory'];
				}
				break;

			case 'no-data':
				message = messages['error-nodata'];
				break;
			default:
				message = messages['error-unknown'];
		}
		$('<div class="alert-box alert"></div>').text(message).appendTo($('.alert-boxes'));
	}

	return function() {
	};
});
