define('QrCodeGenerator',['jquery', 'underscore', 'moment', 'purl', 'QrCodeDownload', 'embed', 'Configuration', 'Analytics'],
	function($, _, moment, purl, DownloadDialog, EmbedDialog, Config, Analytics) {

	var $rootElement,
	    defaultQrCodeType,
	    selectedQrCodeType = defaultQrCodeType = 'text', // TODO restore from localstorage
	    $qrCodeForms,
	    $typeSelectors,
	    $qrCodeActionButtons,
	    $formRoot,
	    // records whether a QR code has been generated for the current type
	    trackedQrCodeEvent = false,
	    // flag if the generator has been fully initialized. Is set by initialize()
	    initialized = false;

	var qrCodeImages = {
		'default': Config.resourceBasePath + 'Images/qr_default.png',
		'error': Config.resourceBasePath + 'Images/qr_error.png',
		'loading': Config.resourceBasePath + 'Images/qr_loading.gif',
		'nodata': Config.resourceBasePath + 'Images/qr_nodata.png'
	};

	var QrCodeGenerator = {
		/**
		 * Whether this generator has more than one type. Read-only
		 */
		isMultiType: false,
		rootElement: '#qr-code-widget',
		types: {}
	};

	/**
	 * Encodes a field so it can be used within a "wifi" QR code.
	 *
	 * @param {string} fieldContents
	 * @see https://code.google.com/p/zxing/wiki/BarcodeContents
	 */
	var encodeFieldContentsForWifi = function(fieldContents) {
		if (fieldContents.search(/(:|;|,|"|\\)/) > -1) {
			fieldContents = fieldContents.replace(/\\/g, "\\\\").replace(/:/g, "\\:").replace(/;/g, "\\;").replace(/,/g, "\\,").replace(/"/g, "\\\"");
		}

		return fieldContents;
	};

	function getQrCodeForm(type) {
		if (!type) {
			return $('.qr-code-form[data-type=' + selectedQrCodeType + ']');
		} else {
			type = type.replace(/[^-a-zA-Z]/g, '');
			return $('.qr-code-form[data-type=' + type + ']');
		}
	}

	QrCodeGenerator.hasQrCodeType = function(type) {
		return getQrCodeForm(type).length > 0;
	};

	QrCodeGenerator.changeQrCodeType = function(type) {
		selectedQrCodeType = type;
		if ($qrCodeForms.filter('[data-type=' + type + ']').length == 0) {
			selectedQrCodeType = $qrCodeForms.first().data('type');
			console.log('Switched type to ' + selectedQrCodeType);
		}
		if (initialized) Analytics.trackEvent('QrCodeGenerator', 'switchType', selectedQrCodeType);
		trackedQrCodeEvent = false;

		$formRoot = getQrCodeForm();

		$typeSelectors.removeClass('selected').filter('[data-type=' + type + ']').addClass('selected');
		$qrCodeForms.hide();
		$formRoot.show();

		if (this.isMultiType && type != defaultQrCodeType) {
			window.location.hash = 't=' + selectedQrCodeType;
			$('#selected-qr-codes-type').text(selectedQrCodeType);

		// this check is not strictly necessary, but if we always set the hash to an empty string here,
		// some browsers will always display just the hash sign at the end of the URL, even if no hash
		// was present before we forcibly set it here
		} else if (window.location.hash != '') {
			window.location.hash = '';
		}
		this.updateQrCodePreview();
	};

	QrCodeGenerator.getSelectedQrCodeType = function() {
		return selectedQrCodeType;
	};

	QrCodeGenerator.updateQrCodePreview = (function() {
		var lastPreviewContents = '', lastUpdateTimestamp = 0;
		var $previewImage = $("#qrcode-preview-image");

		var updateTimeout = 500;
		var updateInProgress = false;

		return function(forceUpdate) {
			var now = new Date().getTime();

			var qrCodeContents = QrCodeGenerator.getQrCodeContents();
			if (qrCodeContents === lastPreviewContents && !forceUpdate) {
				return;
			}
			if (!qrCodeContents || qrCodeContents == '') {
				$previewImage.attr('src', qrCodeImages.nodata);
				$qrCodeActionButtons.hide();
				return;
			}
			if ((lastUpdateTimestamp + updateTimeout > now && !forceUpdate) || updateInProgress) {
				return;
			}
			// NOTE that this will still yield an event if the code type is switched to another type and then switched
			// back – the data of the first code is still present after switching back to it and there is no check here
			// if the contents have really changed
			if (!trackedQrCodeEvent) {
				Analytics.trackEvent('QrCodeGenerator', 'generatedQrCode', QrCodeGenerator.getSelectedQrCodeType());
				trackedQrCodeEvent = true;
			}

			updateInProgress = true;
			lastUpdateTimestamp = now;
			lastPreviewContents = qrCodeContents;

			// switch to the loading indicator...
			$previewImage.attr('src', qrCodeImages.loading);

			// ... and load the preview image. We are using the defaults of the API here
			var previewUrl = Config.qrCodeBaseUri + $.param({data: lastPreviewContents, size: "220x220", margin: 0});
			var tempImage = new Image();
			tempImage.src = previewUrl;

			$(tempImage).ready(function() {
				updateInProgress = false;
				$qrCodeActionButtons.show();
				$previewImage.attr('src', previewUrl);
			});
		};
	})();

	var getFormField = function(fieldName) {
		return $formRoot.find('[name=' + fieldName + ']');
	};

	var readFormField = function(fieldName) {
		var $formField = getFormField(fieldName);
		return getFieldContents($formField);
	};

	var getFieldContents = function($formField) {
		if ($formField.attr('type') == 'checkbox') {
			return $formField.prop('checked') ? $formField.val() : '';
		}
		if ($formField.attr('type') == 'radio') {
			return $formRoot.find('[name=' + $formField.attr('name') + ']:checked').val();
		}
		return $formField.val();
	};

	/**
	 * Returns the contents of the currently selected QR code. It might return an empty string if the user input
	 * cannot be resolved to a valid QR code (e.g. a phone code where no number is entered or a vCard without a name)
	 *
	 * @returns {string}
	 */
	QrCodeGenerator.getQrCodeContents = function(qrCodeType) {
		var contents = '';

		if (!qrCodeType) {
			qrCodeType = selectedQrCodeType;
		}

		if (_.size(this.types) > 0 && this.types[qrCodeType]) {
			var type = this.types[qrCodeType];

			context = {
				readFormField: readFormField
			};

			// get contents via the defined callback
			contents = type.contentsCallback.call(context);

			return contents;
		}

		switch (qrCodeType) {
			case 'url':
				contents = readFormField('url');
				break;

			case 'text':
				contents = readFormField('text');
				break;

			case 'vcard':
				var addFieldToContents = function(field, prefix, suffix) {
					if (readFormField(field)) {
						contents += prefix + readFormField(field) + suffix;
					}
				};
				if (!readFormField('firstname') && !readFormField('lastname') && !readFormField('company')) {
					// no name and company entered; i.e. no valid contents at all
					return '';
				}

				contents = "BEGIN:VCARD\nVERSION:2.1\n";

				if (readFormField('firstname') && readFormField('lastname')) {
					contents += "FN:" + readFormField('firstname') + ' ' + readFormField('lastname') + "\n";
					contents += "N:" + readFormField('lastname') + ';' + readFormField('firstname') + "\n";
				} else if (readFormField('firstname')) {
					contents += "FN:" + readFormField('firstname') + "\n";
					contents += "N:;" + readFormField('firstname') + "\n";
				} else if (readFormField('lastname')) {
					contents += "FN:" + readFormField('lastname') + "\n";
					contents += "N:" + readFormField('lastname') + ";\n";
				}

				addFieldToContents('title', "TITLE:", "\n");
				// NOTE: Some QR code readers are *really* picky about the order of these fields in a vCard.
				// The content type at the beginning of the line is completely ignored, instead they just rely on
				// the order of the fields. Therefore, *never* change the field order without doing proper testing!
				addFieldToContents('phonemobile', "TEL;CELL:", "\n");
				addFieldToContents('phonebusiness', "TEL;WORK;VOICE:", "\n");
				addFieldToContents('phonepersonal', "TEL;HOME;VOICE:", "\n");
				addFieldToContents('mailpersonal', "EMAIL;HOME;INTERNET:", "\n");
				addFieldToContents('mailbusiness', "EMAIL;WORK;INTERNET:", "\n");

				if (readFormField('website')) {
					contents += "URL:" + readFormField('website') + "\n";
				}

				if (readFormField('street') || readFormField('zip') || readFormField('city') || readFormField('country')) {
					contents += "ADR:;;" + readFormField('street') + ";" + readFormField('city')  + ";;" + readFormField('zip') + ";" + readFormField('country') + "\n";
				}

				addFieldToContents('company', "ORG:", "\n");

				contents += "END:VCARD\n";
				break;

			case 'sms':
				var message = readFormField('text');
				if (!message) return '';

				contents = 'SMSTO:' + readFormField('countrycode') + readFormField('areacode') + readFormField('number') + ':' + readFormField('text');
				break;

			case 'call':
				if (!readFormField('number')) return '';

				contents = 'tel:';
				contents += readFormField('countrycode');
				contents += readFormField('areacode');
				contents += readFormField('number');

				break;

			case 'geolocation':
				if (!readFormField('latitude') || !readFormField('longitude')) return '';

				contents += 'geo:';
				contents += (readFormField('latitude-type') == 'S') ? '-' : '';
				contents += readFormField('latitude') + ",";
				contents += (readFormField('longitude-type') == 'W') ? '-' : '';
				contents += readFormField('longitude');
				contents += ',400'; // 150 meters above ground; TODO should this be configurable?

				break;

			case 'event':
				if (!readFormField('from') || !readFormField('to')) {
					return '';
				}
				// moment().zone() returns the difference between the local timezone and UTC (negative values for
				// timezones ahead of UTC, e.g. Germany, positive values for timezones behind UTC, e.g. the USA)
				// Example:
				//   We are in UTC+2 (Germany) and want a time for UTC+3. Timezone will thus be -180 (three
				//   hours ahead of UTC).
				//   The difference between the values entered and the desired values is -60 minutes in this case
				//   (we need to be one hour more ahead of UTC than what was entered entered).
				var localDifferenceToDesiredTimezone = parseInt(readFormField('timezone')) - moment().zone();

				var dateFrom = getFormField('from').datetimepicker('getDate');
				dateFrom = moment(dateFrom).utc().add('m', localDifferenceToDesiredTimezone);
				// The Z has to be escaped with a backslash because otherwise it would result in a timezone string
				// like "+0200"; the VEVENT format mandates using Zulu time (Z; = GMT) for events.
				var dateFromString = dateFrom.format('YYYYMMDDTHHmmss\\Z').replace(/(:|-)/g, '');

				var dateTo = getFormField('to').datetimepicker('getDate');
				dateTo = moment(dateTo).utc().add('m', localDifferenceToDesiredTimezone);
				var dateToString = dateTo.format('YYYYMMDDTHHmmss\\Z').replace(/(:|-)/g, '');

				contents = "BEGIN:VEVENT\nSUMMARY:" + readFormField('description') + "\n";
				contents += "DTSTART:" + dateFromString + "\n";
				contents += "DTEND:" + dateToString + "\n";
				contents += "END:VEVENT";

				break;

			case 'email':
				if (!readFormField('email')) {
					return '';
				}

				contents = 'MATMSG:TO:' + readFormField('email') + ';SUB:' + readFormField('subject') + ';BODY:' + readFormField('text') + ';;';
				break;

			case 'wifi':
				if (!readFormField('ssid')) {
					return '';
				}

				contents = 'WIFI:T:' + readFormField('type') + ';S:' + encodeFieldContentsForWifi(readFormField('ssid'));
				contents += ';P:';
				if (readFormField('type') != 'nopass') {
					contents += encodeFieldContentsForWifi(readFormField('password'));
				}
				contents += ';';
				if (readFormField('hidden')) {
					contents += 'H:true';
				}
				contents += ';';
				break;
		}
		return contents;
	};

	QrCodeGenerator.getQrCodeFields = function(qrCodeType) {
		var fieldContents = {}, $qrCodeForm;

		if (!qrCodeType) {
			$qrCodeForm = $formRoot;
		} else {
			$qrCodeForm = getQrCodeForm(qrCodeType);
		}

		$qrCodeForm.find('input, textarea, select').each(function() {
			fieldContents[$(this).attr('name')] = getFieldContents($(this));
		});
		return fieldContents;
	};

	QrCodeGenerator.initialize = function() {
		$rootElement = $(this.rootElement);
		$qrCodeForms = $rootElement.find('.qr-code-form');
		$typeSelectors = $rootElement.find('div.type-selector');
		$qrCodeActionButtons = $rootElement.find('.action-buttons');
		this.isMultiType = $qrCodeForms.length > 1;

		// don't proceed if there are no QR code forms defined
		if ($qrCodeForms.length == 0) {
			return;
		}
		$qrCodeForms.hide().first().show();
		$qrCodeActionButtons.hide();

		DownloadDialog.initialize(this);
		EmbedDialog.initialize(this);

		// Setup code for the datepicker widget
		require(['jquery.datepicker.de', 'jquery-ui', 'jquery.timepicker', 'jquery.timepicker.de'], function() {
			var $ = require('jquery');

			$qrCodeForms.find('input[type=datetime]').datetimepicker({
				firstDay: 1
			});
		});

		// Setup code for the QR code type selectors
		if (this.isMultiType) {
			var $currentTypeIndicator = $('#selected-qr-codes-type');
			$rootElement.find('.type-selector').on('click', function() {
				var type = $(this).data('type');
				QrCodeGenerator.changeQrCodeType(type);
				// TODO store selected type in localstorage
				QrCodeGenerator.updateQrCodePreview(true);
			}).mouseenter(function() {
				$currentTypeIndicator.data('savedType', $currentTypeIndicator.text());
				$currentTypeIndicator.text($(this).data('type'));
			}).mouseleave(function() {
				$currentTypeIndicator.text(selectedQrCodeType);
			});
		}

		// handling code for the "create dynamic code" checkbox on the URL form
		$('#qrcode-url-dynamic').on('change', function() {
			if ($(this).prop('checked')) {
				$('#qrcode-url-information-dynamic').show();
			} else {
				$('#qrcode-url-information-dynamic').hide();
			}
		});

		// pre-fetch QR code preview images
		var tempImage = new Image();
		tempImage.src = qrCodeImages.loading;
		delete(tempImage);
		tempImage = new Image();
		tempImage.src = qrCodeImages.error;
		delete(tempImage);
		tempImage = new Image();
		tempImage.src = qrCodeImages.nodata;
		delete(tempImage);

		// initialize the update timers
		var timer = null;
		var setUpdateTimer = function() {
			clearTimeout(timer);
			timer = setTimeout(QrCodeGenerator.updateQrCodePreview, 200);
		};
		// We have to add the event handler in two separate steps because IE8 apparently does not support the input
		// event and will not react to the others as well if all handlers are added at the same time
		$qrCodeForms.find('textarea, input[type=text], input[type=datetime], input[type=password]')
			.keyup(setUpdateTimer).on('change', setUpdateTimer).on('blur', setUpdateTimer);//.on('input', setUpdateTimer);
		// Some form elements apparently don't support the keyup event, so we only listen on change events for them
		$qrCodeForms.find('select, input[type=check], input[type=radio]').on('change', setUpdateTimer);

		var setupLengthIndicatorUpdate = function(fieldId, indicatorId) {
			$('#' + fieldId).keyup(function() {
				$('#' + indicatorId).text($(this).val().length);
			}).trigger('keyup');
		};
		setupLengthIndicatorUpdate('qrcode-field-text-text', 'field-text-text-length');
		setupLengthIndicatorUpdate('qrcode-field-email-text', 'field-email-text-length');
		setupLengthIndicatorUpdate('qrcode-field-sms-text', 'field-sms-text-length');

		// Set the timezone selector for the event QR code to the current timezone
		$('#qrcode-field-event-timezone').find('option[value="' + moment().zone() + '"]').attr('selected', true);

		// read selected QR code from the URL, if hash attribute "t" is given
		if ($.url().fparam('t') && this.hasQrCodeType($.url().fparam('t'))) {
			selectedQrCodeType = $.url().fparam('t');
			selectedQrCodeType = selectedQrCodeType.replace(/[^-a-zA-Z]/g, '');
		}

		// Initialize the current QR code
		this.changeQrCodeType(selectedQrCodeType);

		initialized = true;
	};

	return QrCodeGenerator;
});
