define('Configuration',['jquery', 'purl'], function($) {
	var locale = $('html').data('locale');
	var resourceBasePath = '/_Resources/Static/Packages/GoQrMe.Ui/';

	var debug = ($.url().param('jsdebug') !== undefined
		&& console !== undefined && typeof(console) == 'object'
		&& console.debug !== undefined);

	var shareUri = 'http://goqr.me/' + (locale != 'en' ? locale + '/' : '');
	if (debug) {
		console.debug('Locale: ' + locale);
		console.debug('resourceBasePath: ' + resourceBasePath);
		console.debug('Setting up social share for URL ' + shareUri);
	}

	var socialShareResourceBasePath = resourceBasePath + 'Images/SocialSharePrivacy/';

	var AppConfig = {
		SocialSharePrivacy: {
			options: {
				css_path: '', // CSS is included via Sass
				services: {
					facebook: {
						dummy_line_img: socialShareResourceBasePath + (locale != 'en' ? (locale + '/') : '') + 'dummy_facebook.png',
						dummy_box_img: socialShareResourceBasePath + (locale != 'en' ? (locale + '/') : '') + 'dummy_box_facebook.png'
					},
					flattr: {
						dummy_line_img: socialShareResourceBasePath + 'dummy_flattr.png',
						dummy_box_img: socialShareResourceBasePath + 'dummy_box_flattr.png',
						uid: 'goqrme'
					},
					twitter: {
						dummy_line_img: socialShareResourceBasePath + 'dummy_twitter.png',
						dummy_box_img: socialShareResourceBasePath + 'dummy_box_twitter.png'
					},
					gplus: {
						dummy_line_img: socialShareResourceBasePath + 'dummy_gplus.png',
						dummy_box_img: socialShareResourceBasePath + 'dummy_box_gplus.png'
					}
				},
				uri: shareUri
			}
		},
		debug: debug,
		resourceBasePath: resourceBasePath,
		locale: locale,
		qrCodeBaseUri: 'https://api.qrserver.com/v1/create-qr-code/?',
		embedBaseUri: 'http://api.qrserver.com/v1/create-qr-code/?',
		/**
		 * The QR code types defined here contain the following keys:
		 * - contentsCallback: a callable that will return the QR code's contents. Expects to be run in a context
		 *     where "this" exposes the following methods: readFormField
		 */
		QrCodeTypes: {
			text: {
				contentsCallback: function() {
					// TODO implement
				}
			},
			// a complex vCard is a vCard that contains both a personal and a business address
			complexVCard: {
				contentsCallback: function() {
					// see <http://www.imc.org/pdi/vcard-21.doc> for the format specification of vCard 2.1

					var contents = '';

					var self = this;
					function addFieldToContents(field, prefix, suffix) {
						if (self.readFormField(field)) {
							contents += prefix + self.readFormField(field) + suffix;
						}
					}

					/**
					 * Returns the selected value of the given radiobutton
					 *
					 * @param radiobuttonName
					 * @returns string
					 */
					function getRadiobuttonValue(radiobuttonName) {
						return $("[name=" + radiobuttonName + "]:checked").val();
					}

					function isAddressOfType(addressNumber, type) {
						return getRadiobuttonValue('addresstype' + addressNumber) == type;
					}

					function translateTypeRadiobuttonToVcardValue(radiobuttonValue) {
						switch (radiobuttonValue) {
							case 'personal':
								type = 'HOME';
								break;
							case 'business':
								type = 'WORK';
								break;
						}
						return type;
					}

					/**
					 * Returns the contents of an address field set if there are contents inside this field
					 *
					 * @param addressNumber
					 */
					function generateAddressIfUsed(addressNumber) {
						function getField(fieldName) {
							return self.readFormField(fieldName + addressNumber)
						}

						// return an empty string if the address has not been set
						if (!(getField('street') || getField('city') || getField('state') || getField('zip') || getField('country'))) {
							return '';
						}

						var type = translateTypeRadiobuttonToVcardValue(getRadiobuttonValue('addresstype' + addressNumber));
						return "ADR;" + type + ":;;" + getField('street') + ";" + getField('city')
						       + ";" + getField('state') + ";" + getField('zip') + ";"
						       + getField('country') + "\n";
					}

					if (!this.readFormField('firstname') && !this.readFormField('lastname') && !this.readFormField('company')) {
						// no name and company entered; i.e. no valid contents at all
						return '';
					}

					contents = "BEGIN:VCARD\nVERSION:2.1\n";

					if (this.readFormField('firstname') && this.readFormField('lastname')) {
						contents += "FN:" + this.readFormField('firstname') + ' ' + this.readFormField('lastname') + "\n";
						contents += "N:" + this.readFormField('lastname') + ';' + this.readFormField('firstname') + "\n";
					} else if (this.readFormField('firstname')) {
						contents += "FN:" + this.readFormField('firstname') + "\n";
						contents += "N:;" + this.readFormField('firstname') + "\n";
					} else if (this.readFormField('lastname')) {
						contents += "FN:" + this.readFormField('lastname') + "\n";
						contents += "N:" + this.readFormField('lastname') + ";\n";
					}

					addFieldToContents('title', "TITLE:", "\n");
					// NOTE: Some QR code readers are *really* picky about the order of these fields in a vCard.
					// The content type at the beginning of the line is completely ignored, instead they just rely on
					// the order of the fields. Therefore, *never* change the field order without doing proper testing!
					addFieldToContents('phonemobile', "TEL;CELL:", "\n");
					if (isAddressOfType(1, 'business')) {
						addFieldToContents('phone1', "TEL;WORK;VOICE:", "\n");
					}
					if (isAddressOfType(2, 'business')) {
						addFieldToContents('phone2', "TEL;WORK;VOICE:", "\n");
					}
					if (isAddressOfType(1, 'personal')) {
						addFieldToContents('phone1', "TEL;HOME;VOICE:", "\n");
					}
					if (isAddressOfType(2, 'personal')) {
						addFieldToContents('phone2', "TEL;HOME;VOICE:", "\n");
					}
					addFieldToContents('fax', "TEL;;FAX:", "\n");
					if (isAddressOfType(1, 'personal')) {
						addFieldToContents('mail1', "EMAIL;HOME;INTERNET:", "\n");
					}
					if (isAddressOfType(2, 'personal')) {
						addFieldToContents('mail2', "EMAIL;HOME;INTERNET:", "\n");
					}
					if (isAddressOfType(1, 'business')) {
						addFieldToContents('mail1', "EMAIL;WORK;INTERNET:", "\n");
					}
					if (isAddressOfType(2, 'business')) {
						addFieldToContents('mail2', "EMAIL;WORK;INTERNET:", "\n");
					}

					if (this.readFormField('website')) {
						contents += "URL:" + this.readFormField('website') + "\n";
					}

					contents += generateAddressIfUsed(1);
					contents += generateAddressIfUsed(2);

					addFieldToContents('company', "ORG:", "\n");

					contents += "END:VCARD\n";

					return contents;
				}
			}
		}
	};

	return AppConfig;
});
