define('Analytics',['jquery', 'purl', 'Configuration'], function ($, purl, Configuration) {
	function _trackEvent(category, action, label) {
		if (Configuration.debug) console.debug("Tracking event " + category + (action !== undefined ? (':' + action) : '') + (label !== undefined ? (':' + label) : ''));
		window._gaq.push(['_trackEvent', category, action, label]);
	}

	// only attach the analytics event handlers after the DOM is ready.
	$(document).ready(function () {
		var QrCodeGenerator;
		require(['QrCodeGenerator'], function() {
			QrCodeGenerator = require('QrCodeGenerator');
		});

		$('.button-download').on('click', function() {
			_trackEvent('Code download', $(this).data('format'), QrCodeGenerator.getSelectedQrCodeType());
		});

		$('#qr-code-download-dialog').on('opened', function() {
			_trackEvent('Download dialog', 'open', QrCodeGenerator.getSelectedQrCodeType());
		});

		$('#qr-code-embed-dialog').on('opened', function() {
			_trackEvent('Embed dialog', 'open', QrCodeGenerator.getSelectedQrCodeType());
		});

		$('#badge-logo a').on('click', function() {
			_trackEvent('Logo', 'click-badge');
		});

		$('.zazzle-link').on('click', function() {
			_trackEvent('Zazzle dialog', 'open:' + $(this).data('category'), QrCodeGenerator.getSelectedQrCodeType());
		});

		$('#zazzle-button-proceed').on('click', function() {
			_trackEvent('Zazzle dialog', 'redirect:' + $(this).data('category'), QrCodeGenerator.getSelectedQrCodeType());
		});

		$('.link-email').on('click', function() {
			_trackEvent('Mail', $(this).data('message'));
		});

		var url = $.url();
		if (url.param('shop-error') !== undefined) {
			_trackEvent('ZazzleError', url.param('shop-error'));
		}

		$('#uvTabLabel').on('click', function() {
			_trackEvent('UserVoice', 'open');
		});

		$('body').on('click', '#test-link', function() {
			_trackEvent('A/B-Test', 'click', 'test-link');
		})
	});

	return {
		trackEvent: _trackEvent
	}
});
