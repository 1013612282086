define('QrCodeDialogOptions',
	['jquery', 'Configuration', 'dragdealer', 'jscolor'],
	function($, Configuration, Dragdealer, jscolor) {

	/**
	 * The QR code options dialog shows
	 *
	 * @param QrCodeGenerator
	 * @param dialogRootElement
	 * @constructor
	 */
	var QrCodeDialogOptions = function(QrCodeGenerator, dialogRootElement) {
		this.initialized = false;

		jscolor.dir = Configuration.resourceBasePath + 'Images/jscolor/';
		jscolor.init();

		this.QrCodeGenerator = QrCodeGenerator;

		this.$rootElement = $(dialogRootElement);
		this.$rootElement.data('options-dialog-object', this);
		//
		this.$previewImage = this.$rootElement.find('.preview-image');
		this.$fgpicker = this.$rootElement.find('input.fgcolor');
		this.$bgpicker = this.$rootElement.find('input.bgcolor');
		this.qrCodeDownloadSize = 200;

		this.updateCallback = null;

		var parentDialog = this.$rootElement.parents('.reveal-modal');
		if (parentDialog.length > 0) {
			parentDialog.on('opened', $.proxy(this._initializeSizeSlider, this));
		} else {
			// TODO check if the slider is visible
			this._initializeSizeSlider();
		}
		this._initializeColorPickers();
		this._initializeUpdateTriggers();
		this.update();
		this.initialized = true;

		return this;
	};

	QrCodeDialogOptions.prototype._isVisible = function() {
		return this.$rootElement.is(":visible");
	};

	QrCodeDialogOptions.prototype.update = function() {
		if (!this._isVisible()) {
			return;
		}
		this._updateQrCodePreview();
		// The update callback is called in _triggerUpdateEvent
	};

	/**
	 * Returns the selected foreground color as a hex string without the leading "#".
	 *
	 * @returns String
	 */
	QrCodeDialogOptions.prototype.getForegroundColor = function() {
		return this.$fgpicker.val();
	};

	/**
	 * Returns the selected background color as a hex string without the leading "#".
	 *
	 * @returns String
	 */
	QrCodeDialogOptions.prototype.getBackgroundColor = function() {
		return this.$bgpicker.val();
	};

	/**
	 * Returns the quiet zone configured for this QR code.
	 *
	 * @returns {integer}
	 */
	QrCodeDialogOptions.prototype.getQuietZone = function() {
		return this.$rootElement.find('.border option:selected').val()
	};

	QrCodeDialogOptions.prototype.getErrorCorrectionCode = function() {
		return this.$rootElement.find('.ecc').val();
	};

	/**
	 * Initializes the update triggers for ECC level and border size. Update on color changes is triggered
	 * with a delay by the color update code, because we don't want to do this on *every* color change (which
	 * also happens when the mouse is moved during a click).
	 *
	 * @private
	 */
	QrCodeDialogOptions.prototype._initializeUpdateTriggers = function() {
		var self = this;
		var updateCallback = function() {
			self._updateQrCodePreview();
		};
		this.$rootElement.find('.ecc').on('change', updateCallback);
		this.$rootElement.find('.border').on('change', updateCallback);
	};

	QrCodeDialogOptions.prototype._initializeSizeSlider = function() {
		var self = this;
		if (this.dragDealer) {
			return;
		}
		if (this.$rootElement.find('.dragdealer').length == 0) {
			if (Configuration.debug) console.debug('No Dragdealer size slider found');
			return;
		}

		var maximumSize = 1000, minimumSize = 150, stepSize = 25, initialValue = 400,
		    numberOfSteps = Math.round((maximumSize - minimumSize) / stepSize), offset = minimumSize;

		// NOTE: Dragdealer won't work if there is no id on the element
		var sliderId = this.$rootElement.find('.dragdealer').attr('id');
		if (!sliderId) {
			console.error('Could not find size slider id');
		}
		if (Configuration.debug) console.debug('Setting up slider ' + sliderId);
		var $sliderHandle = this.$rootElement.find('.dragdealer .handle');

		this.dragDealer = new Dragdealer(sliderId, {
			steps: numberOfSteps,
			xPrecision: numberOfSteps,
			snap: true,
			x: (((initialValue - minimumSize) / stepSize) / (numberOfSteps - 1)),
			animationCallback: function (x, y) {
				self.qrCodeDownloadSize = (Math.round(x * numberOfSteps) * stepSize) + offset;
				$sliderHandle.text(self.qrCodeDownloadSize);

				clearTimeout(self.sizeUpdateTimer);
				// the size should usually not affect the preview, but only trigger external changes, e.g. for the
				// embed dialog
				self.sizeUpdateTimer = setTimeout($.proxy(self._triggerUpdateEvent, self), 100);
			}
		});
	};

	/**
	 * Creates the color picker objects for background and foreground colors and wires them to the color and QR code
	 * preview update mechanism
	 *
	 * @private
	 */
	QrCodeDialogOptions.prototype._initializeColorPickers = function() {
		if (Configuration.debug) console.debug('Initializing color pickers');

		var self = this;
		this.fgpicker = new jscolor.color(this.$fgpicker[0]);
		this.$fgpicker.on('change', function() {
			self.fgcolor = $(this).val();
			self._updateQrCodePreview();
		});
		this.bgpicker = new jscolor.color(this.$bgpicker[0]);
		this.$bgpicker.on('change', function() {
			self.fgcolor = $(this).val();
			self._updateQrCodePreview();
		});
	};

	/**
	 *
	 * @returns {{color: String, bgcolor: String, data: string, qzone: integer, size: string, ecc: integer}}
	 */
	QrCodeDialogOptions.prototype.getParametersForDownload = function() {
		return {
			color: this.getForegroundColor(),
			bgcolor: this.getBackgroundColor(),
			data: this.QrCodeGenerator.getQrCodeContents(),
			qzone: this.getQuietZone(),
			margin: 0,
			size: this.qrCodeDownloadSize + 'x' + this.qrCodeDownloadSize,
			ecc: this.getErrorCorrectionCode()
		};
	};

	QrCodeDialogOptions.prototype.onUpdate = function(updateCallback) {
		// TODO check if callable
		this.updateCallback = updateCallback;
	};

	/**
	 * Updates the QR code preview shown
	 * @private
	 */
	QrCodeDialogOptions.prototype._updateQrCodePreview = function() {
		var params = this.getParametersForDownload();
		params['size'] = '200x200';

		var previewUrl = Configuration.qrCodeBaseUri + $.param(params);
		if (this.QrCodeGenerator.getQrCodeContents() != '' && previewUrl != this.$previewImage.attr('src')) {
			this.$previewImage.attr('src', previewUrl).data('reveal-id', 'qr-code-download-dialog');

			this._triggerUpdateEvent();
		} else {
			this.$previewImage.data('reveal-id', '');
		}
	};

	/**
	 * Triggers the event to update the preview.
	 * @private
	 */
	QrCodeDialogOptions.prototype._triggerUpdateEvent = function() {
		if (!(this.updateCallback instanceof Function)) {
			return;
		}

		if (Configuration.debug) console.debug("Calling QR code update callback.");
		this.updateCallback.call(this);
	};

	return QrCodeDialogOptions;
});

