define('embed',['jquery', 'Configuration', 'QrCodeDialogOptions'], function($, Configuration, OptionsDialog) {

	var $QrCodeEmbedDialog = $('#qr-code-embed-dialog');
	var QrCodeDialogOptions;
	var initialized;

	var imageTag = '<img src="{img}" alt="qr code" />';

	var getHtml = function(qrCodeImageUrl) {
		var url = 'http://goqr.me/?s=e';

		return imageTag
			.replace('{img}', qrCodeImageUrl.replace(/&/g, '&amp;'));
	};

	var embedBaseUri = Configuration.embedBaseUri ? Configuration.embedBaseUri : Configuration.qrCodeBaseUri;

	// add a callback for QR code options updates
	function updateUrls() {
		console.log("Update embed code");
		var params = QrCodeDialogOptions.getParametersForDownload();
		var qrCodeImageUrl = embedBaseUri + $.param(params);

		$('#qr-code-embed-url').val(qrCodeImageUrl);
		$('#qr-code-embed-html').val(getHtml(qrCodeImageUrl));
	}

	$QrCodeEmbedDialog.on('opened', function() {
		QrCodeDialogOptions.update();
		updateUrls();
	});

	var selectUrls = function() {
		$(this).select();
	};
	$('#qr-code-embed-html, #qr-code-embed-url').click(selectUrls).dblclick(selectUrls).keyup(selectUrls).focus(selectUrls);

	var EmbedDialog = {
		initialize: function(QrCodeGenerator) {
			QrCodeDialogOptions = new OptionsDialog(QrCodeGenerator, $QrCodeEmbedDialog.find('.qr-code-options-dialog'));

			QrCodeDialogOptions.onUpdate(function() {
				updateUrls();
			});

			initialized = true;
		}
	};

	return EmbedDialog;
});
