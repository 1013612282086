define('GoogleAnalytics',['jquery', 'purl'], function ($, purl) {
	var _gaq = _gaq || [];
	if (window) {
		window._gaq = _gaq; // export as global
	}
	_gaq.push(['_setAccount', 'UA-34456728-4']);

	function initializeGoogleAnalytics() {
		var pluginUrl = '//www.google-analytics.com/plugins/ga/inpage_linkid.js';

		_gaq.push(
			['_require', 'inpage_linkid', pluginUrl],
			['_gat._anonymizeIp'],
			['_gat._forceSSL'],
			['_setSiteSpeedSampleRate', 100],
			['_trackPageview']
		);

		var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
		var url = $.url();
		if (url.param('jsdebug') !== undefined) {
			ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/u/ga_debug.js';
		} else {
			ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
		}
		var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
	}


	return {
		initialize: function() {
			initializeGoogleAnalytics();
		},
		setDomain: function(domain) {
			// see <https://developers.google.com/analytics/devguides/collection/gajs/gaTrackingSite?hl=de#domainSubDomains>
			_gaq.push(['_setDomainName', domain]);
		}
	};
});
