define('Pages/QrCodeLogo',['jquery'], function($) {
	$('#logo-offer-dialog').on('open', function() {
		var $iframe = $(this).find('iframe');
		var backendUrl = $iframe.data('backend-url') + 'logo-offer';
		if ($iframe.attr('src') != backendUrl) {
			$iframe.attr('src', backendUrl);
		}
		// Hide the loading indicator once the iframe has been loaded
		$iframe.ready(function() {
			$('#logo-offer-dialog').find('.loading-indicator-wrap').hide();
		})
	});

	window.loadIframe = function(height) {
		document.getElementById('logo-offer-iframe').style.height = (height + 10) + 'px';
	};

});
